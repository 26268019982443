import Cookies from "js-cookie";
import axios from "axios";
import Qs from "qs";

const stripTrailingSlash = (str) => (str.endsWith("/") ? str.slice(0, -1) : str);

const ApiService = {
  // Dear future developer,
  //
  // There's very likely a bug here, that'll stop you from using multiple API versions
  // in the same context. I've happened to notice it, but I'm not fixing it now. Sorry.
  //
  // Kind regards,
  init(version = "v1") {
    if (!window.silversideAxios) {
      const csrfCookieName = document.querySelector("[data-csrf-cookie-name]").dataset
        .csrfCookieName;
      const csrfToken = Cookies.get(csrfCookieName);

      window.silversideAxios = axios.create({
        baseURL: `/api/${version}`,
        headers: {
          common: {
            "X-CSRFToken": csrfToken,
          },
        },
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
    }
  },

  query(resource, params, config = null) {
    return window.silversideAxios.get(resource, { params: params, ...config });
  },

  get(resource, slug = "", params) {
    resource = stripTrailingSlash(resource);
    return window.silversideAxios.get(`${resource}/${slug}/`, { params: params });
  },

  post(resource, params, config = null) {
    if (!resource.endsWith("/")) {
      resource = `${resource}/`;
    }
    if (config) {
      return window.silversideAxios.post(`${resource}`, params, config);
    } else {
      return window.silversideAxios.post(`${resource}`, params);
    }
  },

  update(resource, slug, params) {
    resource = stripTrailingSlash(resource);
    return window.silversideAxios.put(`${resource}/${slug}/`, params);
  },

  patch(resource, slug, params) {
    resource = stripTrailingSlash(resource);
    return window.silversideAxios.patch(`${resource}/${slug}/`, params);
  },

  put(resource, params) {
    resource = stripTrailingSlash(resource);
    return window.silversideAxios.put(`${resource}/`, params);
  },

  delete(resource, slug) {
    resource = stripTrailingSlash(resource);
    return window.silversideAxios.delete(`${resource}/${slug}/`);
  },
};

export default ApiService;

export const ProjectsService = {
  query(params) {
    return ApiService.query("projects/project/", params);
  },

  get(slug, params) {
    return ApiService.get("projects/project", slug, params);
  },

  patch(slug, params) {
    return ApiService.patch("projects/project", slug, params);
  },
};

export const StudentParticipationsService = {
  query(params) {
    return ApiService.query("projects/studentparticipation/", params);
  },

  get(slug) {
    return ApiService.get("projects/studentparticipation", slug);
  },

  post(params) {
    return ApiService.post("projects/studentparticipation", params);
  },

  update(slug, params) {
    return ApiService.update("projects/studentparticipation", slug, params);
  },
};

export const WorkService = {
  query(params) {
    return ApiService.query("projects/work/", params);
  },

  get(slug) {
    return ApiService.get("projects/work/", slug);
  },

  post(params, config) {
    return ApiService.post("projects/work/", params, config);
  },

  update(slug, params) {
    return ApiService.update("projects/work/", slug, params);
  },
};

export const ScalesService = {
  query(params) {
    return ApiService.query("scales/scale/", params);
  },

  get() {
    return ApiService.get("scales/scale/");
  },
};

export const LearningAreasService = {
  query(params) {
    return ApiService.query("scales/learningarea/", params);
  },

  get(slug) {
    return ApiService.get("scales/learningarea/", slug);
  },
};

export const ItemsService = {
  query(params) {
    return ApiService.query("online_participation/questions/item/", params);
  },

  get(slug) {
    return ApiService.get("online_participation/questions/item", slug);
  },

  post(params) {
    return ApiService.post("online_participation/questions/item", params);
  },

  update(slug, params) {
    return ApiService.update("online_participation/questions/item", slug, params);
  },

  patch(slug, params) {
    return ApiService.patch("online_participation/questions/item", slug, params);
  },
};

export const MultipleChoiceOptionsService = {
  query(params) {
    return ApiService.query(
      "online_participation/questions/multiplechoiceoption/",
      params,
    );
  },

  get(slug) {
    return ApiService.get("online_participation/questions/multiplechoiceoption", slug);
  },

  post(params) {
    // Transposing params to multipart form data as this endpoint supports
    // file uploads.
    let form = new FormData();
    for (let key in params) {
      form.append(key, params[key]);
    }
    return ApiService.post(
      "online_participation/questions/multiplechoiceoption",
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  },

  update(slug, params) {
    // Transposing params to multipart form data as this endpoint supports
    // file uploads.
    let form = new FormData();
    for (let key in params) {
      form.append(key, params[key]);
    }
    console.log(form);
    return ApiService.patch(
      "online_participation/questions/multiplechoiceoption",
      slug,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  },

  reorder(pks) {
    return ApiService.post(
      "online_participation/questions/multiplechoiceoption/reorder",
      { pks },
    );
  },

  delete(slug) {
    return ApiService.delete(
      "online_participation/questions/multiplechoiceoption",
      slug,
    );
  },
};

export const ShortResponsePartService = {
  query(params) {
    return ApiService.query(
      "online_participation/questions/shortresponsepart/",
      params,
    );
  },

  get(slug) {
    return ApiService.get("online_participation/questions/shortresponsepart", slug);
  },

  post(params) {
    // Transposing params to multipart form data as this endpoint supports
    // file uploads.
    let form = new FormData();
    for (let key in params) {
      form.append(key, params[key]);
    }
    return ApiService.post("online_participation/questions/shortresponsepart", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  update(slug, params) {
    // Transposing params to multipart form data as this endpoint supports
    // file uploads.
    let form = new FormData();
    for (let key in params) {
      form.append(key, params[key]);
    }
    return ApiService.patch(
      "online_participation/questions/shortresponsepart",
      slug,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  },

  reorder(pks) {
    return ApiService.post("online_participation/questions/shortresponsepart/reorder", {
      pks,
    });
  },

  delete(slug) {
    return ApiService.delete("online_participation/questions/shortresponsepart", slug);
  },
};

export const ItemImageImportService = {
  import(file) {
    let form = new FormData();
    form.append("image", file);
    return ApiService.post("online_participation/questions/item/import_image", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export const AWSPollyService = {
  post(params) {
    return ApiService.post("audio_transcription/polly", params, {
      responseType: "blob",
      baseURL: "/api/v2",
    });
  },
};

export const ParametersService = {
  query(params) {
    return ApiService.query("accounts/parameters/me", params);
  },

  post(params) {
    return ApiService.post("accounts/parameters/me", params);
  },
};

export const ClassService = {
  query(params) {
    return ApiService.query("schools/class/", params);
  },
};

export const EnrolmentService = {
  query(params) {
    return ApiService.query("schools/enrolment/", params);
  },
};

export const AcademicYearService = {
  query(params) {
    return ApiService.query("academicyears/academicyear/", params);
  },
};

export const MultiSchoolDataImportService = {
  query(params) {
    return ApiService.query("dataimport/multischooldataimport/", params);
  },

  get(pk, params) {
    return ApiService.get("dataimport/multischooldataimport", pk, params);
  },

  post(params, config) {
    return ApiService.post("dataimport/multischooldataimport/", params, config);
  },
};

export const PeopleService = {
  query(params) {
    return ApiService.query("people/person/", params);
  },
};
